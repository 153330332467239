/* eslint-disable */

/***
 * This file is specifically for making simple ajax calls(uses localStorage for caching)
 *
 * Parameters for makeHttpRequest method:
 *   {
 *       method: String (optional),
 *       url: String,
 *       params: String | Object (optional),
 *       headers: Object (optional),
 *       storage_key: String (optional)
 *   }
 *
 * Usage:
 *     import makeHttpRequest from '../helpers/ajax'
 *     var request = {url: ___, method: ___, storage_key: ___}
 *     makeHttpRequest(request).then(function(response) {
 *           ......
 *      }, function(error){
 *          .......
 *      });
 */

import appStorage from './app-storage';

var makeHttpRequest = function(options) {
    if(!options.url){
      return Promise.reject({"error": 'URL not defined'});
    }

    //Use cache if storage_key is available
    var storage_key = options.storage_key;
    var expiration = options.expTime ? options.expTime : 60 * 60 * 1000;
    if(storage_key){
        var storageData = appStorage.get(storage_key);
        if(storageData !== null){
            return Promise.resolve(storageData);
        }
    }

    // Make an XMLHttpRequest and return a promise
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open(options.method || "GET", options.url);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          var successData =  xhr.responseText;
          if(successData && successData.toLocaleLowerCase() == "notfound") {
            resolve(successData);
          }
          try {
            var jsonSuccessData = JSON.parse(successData);
            var stringifyedSuccessData = JSON.stringify(successData);
            if(storage_key && stringifyedSuccessData && stringifyedSuccessData.length > 0) {
              stringifyedSuccessData = stringifyedSuccessData.toLocaleLowerCase();
                if((jsonSuccessData.hasOwnProperty("hasErrors") && jsonSuccessData.hasErrors) || jsonSuccessData.message == "Something went wrong" || jsonSuccessData.term =="Not Found") {
                  var failureData = {status: this.status, statusText: xhr.statusText};
                  reject(JSON.stringify(failureData));
              } else {
                  appStorage.set(storage_key, successData, expiration);
              }
            }
          }
          catch (error) {
            var parsedData = parseXml(successData);
            var xmlParsedData = parsedData.documentElement.outerHTML.toLocaleLowerCase();
            if(xmlParsedData.indexOf("error") > 0 || xmlParsedData.indexOf("not found") > 0) {
              var failureData = {status: this.status, statusText: xhr.statusText};
              reject(JSON.stringify(failureData));
            } else {
              appStorage.set(storage_key, successData, expiration);
            }
          }
          resolve(successData);
        } else {
          var failureData = {status: this.status, statusText: xhr.statusText};
          reject(JSON.stringify(failureData));
        }
      };

      xhr.onerror = function () {
        var failureData = {status: this.status, statusText: xhr.statusText};
        reject(JSON.stringify(failureData));
      };

      if (options.headers) {
        Object.keys(options.headers).forEach(function (key) {
          xhr.setRequestHeader(key, options.headers[key]);
        });
      }

      if(options.method === 'POST'){
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(options.params));
      }else{
        var params = options.params;
        if (params && typeof params === 'object') {
          params = Object.keys(params).map(function (key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
          }).join('&');
        }
        xhr.send(params);
      }
    });
}

var parseXml = function(xmlString) {
  return new window.DOMParser().parseFromString(xmlString, "text/xml");
}

export default makeHttpRequest;

/* eslint-enable */
