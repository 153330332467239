/**
 * This file gets lowest APR from token
 */
import wwwUrlEnv from '../helpers/www-url-env';
import makeHttpRequest from '../helpers/ajax';

export default {
    GetTokenValue(token, callBackFunction) {
        if (!token) return false;
        if (typeof callBackFunction != 'function') return false;
        const self = this;
        self.callBackFunction = callBackFunction;
        const url = `${wwwUrlEnv.getWWWURL()}/wp-json/token/v1/?slug=${token}`;
        const request = { url, storage_key: token, expTime: 15 * 60 * 1000 };
        return makeHttpRequest(request).then((response) => {
            self.callBackFunction(JSON.parse(response).value);
        }, (error) => {
            console.warn(`There is some issue with network call, please try again${error}`);
        });
    },
};
