export default {
    convertLoanTermInMonths(LoanTerm) {
        return LoanTerm * 12;
    },
    convertInterestRatePerMonths(InterestRate) {
        return InterestRate / 1200;
    },
    /*
        amount : total amount balance
        term : months (#yr*12)
        apr : rate/month (rate/12)
    */
    getPayment(amount, term, apr) {
        /* Calculates the monthly payment from annual percentage
        rate, term of loan in months and loan amount. * */
        let acc = 0;
        const base = 1 + apr;
        for (let i = 1; i <= term; i++) {
            acc += base ** -i;
        }
        return amount / acc;
    },
    monthlyPaymentUsingLoanTermInYears(LoanAmount, LoanTerm, InterestRate) {
        return this.getPayment(LoanAmount, this.convertLoanTermInMonths(LoanTerm), this.convertInterestRatePerMonths(InterestRate));
    },
    getMonthsLeft(CurrentInterestRate, CurrentMortgageBalance, CurrentMonthlyPayment) {
        const interestrate = CurrentInterestRate / 1200;
        const monthsleftcalcnumo = -Math.log(1 - interestrate * (CurrentMortgageBalance / CurrentMonthlyPayment));
        const monthsleftcalcdeno = Math.log(1 + interestrate);
        const MonthsLeftOnCurrentLoan = Math.round(monthsleftcalcnumo / monthsleftcalcdeno);
        return isNaN(MonthsLeftOnCurrentLoan) ? 0 : MonthsLeftOnCurrentLoan;
    },
    monthlyPaymentForRefinance(MonthsLeftOnCurrentLoan, CurrentMortgageBalance, CurrentMonthlyPayment, LoanTermInYears, NewInterestRate) {
        return this.monthlyPaymentUsingLoanTermInYears(CurrentMortgageBalance, LoanTermInYears, NewInterestRate);
    },
    getIntrestRate(termmonth, monthlypayment, currentbalance) {
        // Formula:- initialGuess = (2 (N * P - C)) / (N * C)
        const initialguess = 2 * (termmonth * monthlypayment - currentbalance) / (termmonth * currentbalance);
        const interestRate = this.getFinalIntrestRate(initialguess, termmonth, monthlypayment, currentbalance);
        return interestRate * 100;
    },
    getFinalIntrestRate(interestRateValue, termmonth, monthlypayment, currentbalance) {
        let newinterestrate = 0;
        let guess = 0;
        let interestRate = interestRateValue;

        // eslint-disable-next-line eqeqeq
        while (Math.round(interestRate * 100, 5) != Math.round(newinterestrate, 5) && guess < 20) {
            guess++;
            newinterestrate = interestRate;
            // Formula:-  CurrentGuess = Guess - ( (P - ( P * ( (1 + Guess )^-N ) ) - (Guess * C) ) / ( ( N * P * ( (1 + Guess) ^ (-N -1) ) ) - C )

            interestRate = newinterestrate - (monthlypayment - monthlypayment * (1 + newinterestrate) ** -termmonth - newinterestrate * currentbalance) / (termmonth * monthlypayment * (1 + newinterestrate) ** (-termmonth - 1) - currentbalance);
        }
        return interestRate;
    },
    minimumPayment(LoanAmount, InterestRate) {
        return this.convertInterestRatePerMonths(InterestRate) * LoanAmount;
    },
    getHomeEquityLoan(currentAppraisedHomeValue, amountOwe) {
        const ltvRatio = 0.85;
        return Math.round(ltvRatio * currentAppraisedHomeValue - amountOwe);
    },
    compute_PMT(principle, rate, months) {
        return principle * rate / (1 - (1 + rate) ** (-1 * months)) * 100 / 100;
    },
    compute_PMI(ltv, amount) {
        let pmi = 0;
        if (ltv >= 0.8) {
            pmi = amount * 0.0044 / 12;
        }
        return pmi;
    },
    calculateHomeRates(data, type) {
        const calculatedData = {};
        const income = parseInt(data.annualIncome, 10) / 12;
        const incometaxes = Math.round(income * 0.3);
        const debts = parseInt(data.monthlyExpenses, 10);
        const loanterm = parseInt(data.loanterm, 10) * 12;
        const downpayment = parseInt(data.estimatedDownPayment, 10);
        const hoa = parseInt(data.hoa, 10) / 12;
        const hoi = parseInt(data.homeInsurance, 10) / 12;
        const rate = parseFloat(data.mortgageRate) / 1200;
        const proptaxrate = parseFloat(data.propertyTaxes) / 1200;
        const proptaxper = parseFloat(data.propertyTaxes);
        let proptaxval = 0;
        const pct = data.debtToIncome / 100;
        let loanamount = 0;
        let homeprice = 0;
        let proptax = 0;
        let pmi = 0;
        let ltv = 0;
        let totalmonthlypayment = 0;
        let pi = 0;
        let pmirate = 0;
        const fixedcosts = debts + hoa + hoi;
        const mortgagepayment = income * pct - fixedcosts;
        const factor = ((1 + rate) ** loanterm - 1) / ((1 + rate) ** loanterm * rate);
        const tmphomeprice = (mortgagepayment * factor + downpayment) / (1 + factor * (proptaxrate + pmirate));
        const tmploanamount = tmphomeprice - downpayment;
        if (data.pmi) {
            ltv = tmploanamount / tmphomeprice;
            if (ltv > 0.8) {
                pmirate = ltv * 0.0044 / 12;
            }
        }
        homeprice = (mortgagepayment * factor + downpayment) / (1 + factor * (proptaxrate + pmirate));
        loanamount = homeprice - downpayment;
        proptax = homeprice * proptaxrate;
        proptaxval = homeprice * proptaxper / 100;
        pmi = 0;
        if (data.pmi) {
            ltv = loanamount / homeprice;
            pmi = this.compute_PMI(ltv, loanamount);
        }
        pi = this.compute_PMT(loanamount, rate, loanterm);
        totalmonthlypayment = pi + pmi + hoa + hoi + proptax;
        const leftover = income - totalmonthlypayment - incometaxes - debts;
        if (type === 'calculate') {
            if (mortgagepayment < 0 || totalmonthlypayment > income) {
                calculatedData.payment = 0;
                calculatedData.loanamount = '$___,___';
                calculatedData.homeprice = '$___,___';
                calculatedData.isValid = false;
            } else {
                calculatedData.payment = Math.round(totalmonthlypayment);
                calculatedData.loanamount = Math.round(loanamount);
                calculatedData.homeprice = Math.round(homeprice);
                calculatedData.isValid = true;
            }
            calculatedData.debts = Math.round(debts);
            calculatedData.leftover = Math.round(leftover);
            calculatedData.taxes = Math.round(incometaxes);
            calculatedData.income = Math.round(income);
            return calculatedData;
        }
        if (type === 'calculateDownpayment') {
            return Math.round(downpayment / homeprice * 100);
        }
        if (type === 'calculatePropertyTaxval') {
            return Math.round(proptaxval);
        }
        return calculatedData;
    },
    getMonthlyPrincipalAndIntreset(mortgageAmount, amortizationPeriodInMonths, monthlyInterestRatePerc) {
        return mortgageAmount * (monthlyInterestRatePerc * (1 + monthlyInterestRatePerc) ** amortizationPeriodInMonths / ((1 + monthlyInterestRatePerc) ** amortizationPeriodInMonths - 1));
    },
    getTotalLoanAmount(TotalMoPayment, interestRatePercantage, TermMonths) {
        return TotalMoPayment * (((1 + interestRatePercantage) ** TermMonths - 1) / (interestRatePercantage * (1 + interestRatePercantage) ** TermMonths));
    },
    CUMIPMT(loanAmount, end, mortgagePayment, rate) {
        const payment = mortgagePayment;
        let interest = 0.0;
        let totalinterest = 0.0;
        let balance = loanAmount;
        let principal = 0.0;
        for (let i = 1; i <= end; i++) {
            interest = balance * rate;
            totalinterest += interest;
            principal = payment - interest;
            balance -= principal;
        }
        return Math.round(totalinterest);
    },
    /* Note: This function is specifically for non-monthly payment amounts
        to get the total number of payments in the amortization.

        amount: loan amount
        perYear: number of payments in a year (ex. 26 for biweekly, 52 for weekly)
        interestRate: the loan rate / 12 (ex. 6% / 12 = 0.005)
        payment: the raw payment amount for the period. note: for biweekly this will = (monthly payment / 13) / 26.
                  for weekly this will = (monthly payment / 14) / 52.
    */
    getTotalPayments(amount, perYear, interestRate, payment) {
        let balance = amount;
        let periodInterest = 0;
        let periodPrincipal = 0;
        let i = 1;

        while (balance > 0) {
            periodInterest = interestRate * balance / (perYear / 12);
            if (balance - payment <= 0) {
                // eslint-disable-next-line no-param-reassign
                payment = balance;
                balance = 0;
            }

            periodPrincipal = payment - periodInterest;

            if (balance !== 0) {
                balance -= periodPrincipal;
            }
            i++;
        }

        return i;
    },
};
