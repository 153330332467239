/**
 * This file helps redirection using splitter or final destination url
 */
import URLs from '../constants/urls';

export default {
    methods: {
        redirectToDestinationUrl(name) {
            const self = this;
            if (typeof self.DestinationURL !== 'undefined' && self.DestinationURL !== '') {
                if (name) {
                    window.open(self.DestinationURL, name);
                } else {
                    window.open(self.DestinationURL, '_self');
                }
            }
        },
        setDestinationBaseURL() {
            const self = this;
            let url = '';
            if (typeof self.DestinationLinkOrId !== 'undefined' && self.DestinationLinkOrId !== '') {
                if (self.DestinationLinkOrId.indexOf('http') == 0) { // eslint-disable-line
                    url = self.DestinationLinkOrId;
                } else {
                    url = `${URLs.LT_WWW_SPITTER_URL + self.DestinationLinkOrId}`;
                }
            }
            return url;
        },
        getRcodeIcode() {
            let icode;
            let rcode;

            if (sessionStorage.icode !== undefined && !isNaN(sessionStorage.icode)) {
                icode = `${sessionStorage.icode}`;
            }
            if (sessionStorage.rcode !== undefined && !isNaN(sessionStorage.rcode)) {
                rcode = `${sessionStorage.rcode}`;
            }
            return { icode, rcode };
        },
    },
};
